import { effect, signal, WritableSignal } from "@angular/core";

export function debounced<T>(source: WritableSignal<T>, timeout: number): WritableSignal<T> {
  const debounced = signal<T>(source());

  effect((onCleanup) => {
    const value = source();
    const t = setTimeout(() => debounced.set(value), timeout);

    onCleanup(() => clearTimeout(t));
  });

  return debounced;
}
