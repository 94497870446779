import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AuthService, NotificationService } from '@services';

@Directive({
  selector: 'a[appAuthorize]',
})
export class AuthorizeDirective {
  @Input() appAuthorize: boolean = false;

  constructor(
    private el: ElementRef,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) { }

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent) {
    if (!this.appAuthorize) {
      return;
    }
    console.log("auth directive");

    event.preventDefault();

    this.authService.getOneTimeToken().subscribe({
      next: (token: string) => {
        const href = this.el.nativeElement.href;
        const separator = href.indexOf('?') === -1 ? '?' : '&';

        const link = document.createElement('a');
        link.href = `${href}${separator}token=${token}`;
        link.download = this.el.nativeElement.download;
        link.target = this.el.nativeElement.target;

        link.click();
      },
      error: (err) => {
        console.error(err);
        this.notificationService.error(
          'Failed to authorize the download request.',
        );
      },
    });
  }
}
