import {
  Component,
  forwardRef,
  input,
  output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlStatus,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { first } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlSearchComponent),
      multi: true,
    },
  ],
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
})
export class FormControlSearchComponent implements ControlValueAccessor {
  isLoading = input(false);
  placeholder = input<string>('datasets');
  focusChange = output<{ isFocused: boolean; event: FocusEvent }>();

  public isDisabled: boolean;
  public inputFormControl: FormControl;
  public isAdvancedSearchOpen: boolean;

  constructor() {
    this.isAdvancedSearchOpen = false;
    this.isDisabled = false;
    this.inputFormControl = new FormControl();
  }

  public blur(event: Event) {
    (event.target as HTMLInputElement)?.blur();
  }

  writeValue(obj: string): void {
    this.inputFormControl.setValue(obj, {
      emitEvent: false,
    });
  }

  registerOnChange(fn: (v: string) => void): void {
    this.inputFormControl.valueChanges.subscribe((v: string) => {
      fn(v);
    });
  }

  registerOnTouched(fn: (v: FormControlStatus) => void): void {
    this.inputFormControl.statusChanges
      .pipe(first(() => this.inputFormControl.touched))
      .subscribe((v: FormControlStatus) => {
        fn(v);
      });
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
