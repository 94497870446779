export interface Prefix {
  id: string;
  prefix: string;
  datasetId: string;
  fileCount: number;
  byteSize: number;
}

export interface Directory {
  title: string;
  path: string;
  dir: Map<string, string>;
  prev: string;
  count: number;
  size: number;
}

export function parseDirectories(items: Prefix[]): Map<string, Directory> {
  const directories = new Map<string, Directory>();

  for (const dir of items) {
    let path = '';
    let prev = '';
    let segments = dir.prefix.split('/');
    if (segments.length > 1) {
      segments = segments.slice(0, segments.length - 1);
    }
    for (const seg of segments) {
      prev = path;
      path += seg + '/';

      if (!directories.has(path)) {
        directories.set(path, {
          title: seg || '/',
          path: path,
          dir: new Map(),
          prev: prev,
          count: dir.fileCount,
          size: dir.byteSize,
        } as Directory);
      } else {
        const currentPath = directories.get(path)!;
        directories.set(path, {
          title: currentPath.title,
          path: currentPath.path,
          dir: currentPath.dir,
          prev: prev,
          count: currentPath.count + dir.fileCount,
          size: currentPath.size + dir.byteSize,
        } as Directory);
      }

      if (prev !== '') {
        const previousPath = directories.get(prev)!;
        directories.set(prev, {
          title: previousPath.title,
          path: previousPath.path,
          dir: previousPath.dir.set(path, seg),
          prev: previousPath.prev,
          count: previousPath.count,
          size: previousPath.size,
        } as Directory);
      }
    }
  }

  return directories;
}
