<h2 mat-dialog-title>Upload</h2>

<mat-dialog-content>
  @if (isLoading()) {
    <p>Files are being uploaded. Please wait...</p>
  } @else if (failures().length) {
    <p>
      Failed to upload the following files. Please try again or contact an
      administrator.
    </p>

    <mat-list>
      @for (file of failures(); track file.id) {
        <mat-list-item>
          <span matListItemIcon>
            @if (file.errorMessage) {
              <mat-icon class="npdc-error-icon"> error_circle </mat-icon>
            } @else {
              <mat-icon> check_circle </mat-icon>
            }
          </span>
          <span matListItemTitle>{{ file.fileName }}</span>
          <span matListItemLine>{{ file.errorMessage || '' }}</span>
        </mat-list-item>
      }
    </mat-list>
  } @else {
    <p>All files have been successfully uploaded. </p>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if (isLoading()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
    <button mat-button type="button" [mat-dialog-close]="true">Close</button>
  }
</mat-dialog-actions>
