import { AsyncPipe, DatePipe, SlicePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { AuthorizeDirective } from '@directives';
import { environment } from '@environment';
import { Attachment } from '@models';
import { HumanReadableSizePipe, ResolveUserPipe } from '@pipes';

@Component({
  selector: 'app-attachment-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  imports: [
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    AuthorizeDirective,
    ResolveUserPipe,
    HumanReadableSizePipe,
    AsyncPipe,
    DatePipe,
    SlicePipe,
  ],
})
export class AttachmentItemComponent {
  public fileHostUrl: string;
  public attachment: Attachment;
  public isDatasetPublic: boolean;

  constructor(
    public dialogRef: MatDialogRef<AttachmentItemComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      attachment: Attachment;
      isDatasetPublic: boolean;
    },
  ) {
    this.fileHostUrl = environment.kinkoHost;
    this.attachment = data.attachment;
    this.isDatasetPublic = data.isDatasetPublic;
  }

  public get isPublic(): boolean {
    if (this.attachment?.released === '0001-01-01T00:00:00Z') {
      return true;
    }

    return Date.parse(this.attachment.released) < Date.now();
  }
}
