import { Component, computed, inject, Inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FileUploadResponse } from '@models';
import { AttachmentService, NotificationService } from '@services';

@Component({
  selector: 'app-attachment-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  imports: [
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
  ],
})
export class AttachmentCreateComponent implements OnInit {
  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);

  results = signal<FileUploadResponse[]>([]);
  failures = computed(() => this.results().filter(r => !!r.errorMessage));
  isLoading = signal(true);

  constructor(
    public dialogRef: MatDialogRef<AttachmentCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      datasetId: string,
      files: FileList,
    }
  ) { }

  ngOnInit(): void {
    this.attachmentService
      .createAttachment(this.data.datasetId, this.data.files, new Date())
      .subscribe({
        next: (responses: FileUploadResponse[]) => {
          this.results.set(responses);

          if (this.failures().length) {
            this.notificationService.error('Failed to upload one or multiple files.');
          } else {
            this.dialogRef.close();
          }
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to upload files.');
          this.dialogRef.close();
        },
      }).add(() => {
        this.isLoading.set(false);
      });
  }
}
