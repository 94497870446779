import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { User } from '@models';
import { CacheService, LocalStorageKey, LocalStorageService } from '@services';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = signal<User | null>(null);

  public user$: Observable<User | null>;
  public isLoggedIn: boolean;

  private userSubject: BehaviorSubject<User | null>;

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private cache: CacheService,
  ) {
    const user = localStorageService.get<User>(LocalStorageKey.User);

    this.userSubject = new BehaviorSubject(user);
    this.user$ = this.userSubject.asObservable();
    this.isLoggedIn = !!user;

    if (user?.id) {
      this.cache.users.set(user.id, this.userSubject);
    }

    this.user.set(user);
  }

  logIn(username: string, password: string): Observable<User> {
    const endpoint = `${environment.komainuHost}/authenticate/`;

    return this.http
      .get<User>(endpoint, {
        headers: new HttpHeaders({
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        }),
      })
      .pipe(
        tap((user: User) => {
          this.localStorageService.set(LocalStorageKey.User, user);
          this.userSubject.next(user);
          this.isLoggedIn = true;
          this.user.set(user);
        }),
      );
  }

  logOut() {
    this.localStorageService.remove(LocalStorageKey.User);
    this.userSubject.next(null);
    this.isLoggedIn = false;
    this.user.set(null);
  }

  getOneTimeToken(): Observable<string> {
    return this.http
      .post<{ token: string }>(`${environment.komainuHost}/ott/`, null, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(
        map((response: { token: string }) => {
          return response.token;
        }),
      );
  }

  confirmPasswordRecovery(token: string, password: string): Observable<null> {
    return this.http.post<null>(
      `${environment.komainuHost}/recover/confirm/`,
      {
        password: password,
        recoveryToken: token,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }
}
