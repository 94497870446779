<div class="prefix">
  @if (isLoading()) {
    <mat-spinner matPrefix [diameter]="24"></mat-spinner>
  } @else {
    <mat-icon>search</mat-icon>
  }
</div>

<input
  type="text"
  class="input"
  [placeholder]="'Search in ' + placeholder()"
  (keydown.enter)="blur($event)"
  (keydown.escape)="blur($event)"
  (focus)="focusChange.emit({ isFocused: true, event: $event })"
  (focusout)="focusChange.emit({ isFocused: false, event: $event })"
  [formControl]="inputFormControl" />

@if (inputFormControl.value) {
  <button
    mat-icon-button
    (click)="inputFormControl.reset()"
    matTooltip="Clear search"
    ><mat-icon>close</mat-icon></button
  >
}

<ng-content></ng-content>
