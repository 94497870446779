<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    [disableClose]="(mediaService.isLandscape$ | async) ? true : false"
    [mode]="(mediaService.isLandscape$ | async) ? 'side' : 'over'"
    [opened]="(mediaService.isLandscape$ | async) ? true : false">
    <div
      class="sidenav-content"
      [class.sidenav-content__side]="
        (mediaService.isLandscape$ | async) === false
      ">
      <app-sidenav> </app-sidenav>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="npdc-content">
      <app-toolbar>
        @if ((mediaService.isLandscape$ | async) === false) {
          <button
            mat-icon-button
            class="sidenav-button"
            matTooltip="Toggle navigation drawer"
            (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>

          <a mat-button routerLink="/">Norwegian Polar Data Centre</a>
        }
      </app-toolbar>

      <div class="npdc-content__outlet">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
