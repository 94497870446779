import { Component, inject, Inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PrefixValidator } from '@validators';
import { Attachment } from '@models';
import { AttachmentService, NotificationService } from '@services';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-attachment-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
  ],
})
export class AttachmentUpdateComponent {
  public form: FormGroup;

  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);

  isLoading = signal(true);

  constructor(
    public dialogRef: MatDialogRef<AttachmentUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) private attachment: Attachment,
  ) {
    this.form = new FormGroup({
      filename: new FormControl(attachment?.filename, [Validators.required]),
      prefix: new FormControl(attachment?.prefix, [
        Validators.required,
        PrefixValidator,
      ]),
      title: new FormControl(attachment?.title, []),
      description: new FormControl(attachment?.description, []),
      releaseDate: new FormControl(attachment?.released, []),
    });
  }

  public submit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    const values = this.form.value;
    const requestBody = {
      filename: values.filename,
      prefix: values.prefix,
      title: values.title,
      description: values.description,
      released: values.releaseDate,
      licence: this.attachment.licence,
    } as Attachment;

    this.isLoading.set(true);

    this.attachmentService
      .updateAttachment(this.attachment.datasetId, this.attachment.id, requestBody)
      .subscribe({
        next: (result: Attachment) => {
          this.dialogRef.close(result);
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to update file metadata.');
        },
      }).add(() => {
        this.isLoading.set(false);
      });
  }
}
