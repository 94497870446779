import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PrefixValidator } from '@validators';

@Component({
  selector: 'app-attachment-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [
    MatButtonModule,
    MatSlideToggleModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
  ],
})
export class AttachmentSearchComponent {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AttachmentSearchComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      search: string;
      from: string | null;
      until: string | null;
      prefix: string | null;
    },
  ) {
    this.form = new FormGroup({
      search: new FormControl(data?.search || ''),
      from: new FormControl(data?.from || null),
      until: new FormControl(data?.until || null),
      prefix: new FormControl(data?.prefix || '', [PrefixValidator]),
      disablePrefix: new FormControl(!data?.prefix),
    });
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    const value = this.form.value;
    if (value.disablePrefix) {
      value.prefix = '';
    } else if (value.prefix === '') {
      value.prefix = '/';
    }

    this.dialogRef.close(value);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
