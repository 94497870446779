import { Component, inject, Inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Attachment } from '@models';
import { AttachmentService, NotificationService } from '@services';

@Component({
  selector: 'app-attachment-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  imports: [
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
  ],
})
export class AttachmentDeleteComponent implements OnInit {
  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);

  isLoading = signal(true);
  failures = signal<Attachment[]>([]);

  constructor(
    public dialogRef: MatDialogRef<AttachmentDeleteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Attachment[],
  ) { }

  ngOnInit(): void {
    this.data.forEach(attachment => {
      this.attachmentService.deleteAttachment(attachment.datasetId, attachment.id).subscribe({
        error: (err) => {
          console.error(err);
          this.failures.update(f => [...f, attachment]);
        },
      }).add(() => {
        this.isLoading.set(false);

        if (this.failures().length) {
          this.notificationService.error("Failed to delete one or multiple files.");
        } else {
          this.dialogRef.close();
        }
      });
    });
  }
}
