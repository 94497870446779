<h2 mat-dialog-title>Delete</h2>

<mat-dialog-content>
  @if (isLoading()) {
    <p>Files are being deleted. Please wait...</p>
  } @else if (failures().length) {
    <p>
      Failed to delete the following files. Please try again or contact an
      administrator.
    </p>

    <mat-list>
      @for (file of failures(); track file.id) {
        <mat-list-item>
          <span matListItemIcon>
            <mat-icon class="npdc-error-icon">error_circle</mat-icon>
          </span>
          <span matListItemTitle>{{ file.filename }}</span>
        </mat-list-item>
      }
    </mat-list>
  } @else {
    <p>All (selected) files have been successfully deleted. </p>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if (isLoading()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
    <button mat-button type="button" [mat-dialog-close]="true">Close</button>
  }
</mat-dialog-actions>
