import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadableSize',
})
export class HumanReadableSizePipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined || value < 0) {
      return '';
    }

    const labels: string[] = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB'];

    for (let i = labels.length; i > 0; i--) {
      if (value < Math.pow(1024, i)) {
        continue;
      }

      return `${(value / Math.pow(1024, i)).toFixed(2)} ${labels[i - 1]}`;
    }

    return `${value} Bytes`;
  }
}
