import { Component, computed, effect, inject, input, OnInit, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { Attachment, AttachmentListResponse, Dataset, DatasetType, debounced, Directory, parseDirectories, PrefixListResponse } from "@models";
import { AttachmentService, AuthService, NotificationService } from "@services";
import { AttachmentItemComponent } from "../item/item.component";
import { environment } from "@environment";
import { AuthorizeDirective, StopPropagationDirective } from "@directives";
import { CommonModule, DatePipe, } from "@angular/common";
import { CompactNumberPipe, HumanReadableSizePipe } from "@pipes";
import { FormControlSearchComponent } from "@components/form-control/search/search.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { AttachmentSearchComponent } from "@components/attachment/search/search.component";
import { MatMenuModule } from "@angular/material/menu";

@Component({
  selector: 'app-attachment-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    CommonModule,
    DatePipe,
    FormsModule,

    MatListModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatChipsModule,
    MatMenuModule,

    AuthorizeDirective,
    StopPropagationDirective,
    HumanReadableSizePipe,
    CompactNumberPipe,

    FormControlSearchComponent,
  ],
})
export class AttachmentListComponent implements OnInit {
  host = environment.kinkoHost;

  dataset = input.required<Dataset>()

  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);
  dialog = inject(MatDialog);
  authService = inject(AuthService);

  isLoading = signal<boolean>(true);
  isInitialized = signal<boolean>(false);
  directories = signal<Map<string, Directory>>(new Map<string, Directory>());
  subdirectories = signal<string[]>([]);
  currentDirectory = signal('');
  attachments = signal<Attachment[]>([]);
  attachmentCount = signal(0);
  page = signal<PageEvent>({
    length: 0,
    pageIndex: 0,
    pageSize: 10,
  } as PageEvent);
  search = signal('');
  q = debounced(this.search, 500);
  from = signal<Date | null>(null);
  until = signal<Date | null>(null);

  queryParams = computed(() => {
    const params: string[] = ["order=created:desc"];

    if (this.search()) {
      params.push(`q=${this.search()}`);
    } else {
      params.push("recursive=true");
    }

    if (this.from()) {
      params.push(`from=${this.from()}`);
    }

    if (this.until()) {
      params.push(`until=${this.until()}`);
    }

    if (this.currentDirectory()) {
      params.push(`prefix=${this.currentDirectory()}`);
    }

    return params.join("&");
  });

  constructor() {
    // set loading state when user starts typing
    effect(() => {
      this.search();
      this.isLoading.set(true);
    });

    // set page to 0 on filter changes
    effect(() => {
      if (this.q() || this.from() || this.until() || this.currentDirectory() !== '/') {
        this.page.update(p => {
          return {
            ...p,
            pageIndex: 0,
          };
        });
      }
    })

    effect(() => {
      if (!this.isInitialized()) {
        return;
      }

      this.isLoading.set(true);

      const page = this.page();
      const doCount = page.pageIndex == 0;
      const doRecursiveSearch = !!(this.q() || this.from() || this.until());

      this.attachmentService.getAttachments(
        this.dataset().id,
        page.pageIndex * page.pageSize,
        page.pageSize,
        doCount,
        {
          q: this.q(),
          prefix: this.currentDirectory(),
          from: this.from(),
          until: this.until(),
        },
        [{ column: 'created', direction: 'desc' }],
        doRecursiveSearch,
        !doRecursiveSearch,
      ).subscribe({
        next: (result: AttachmentListResponse) => {
          if (doCount) {
            this.attachmentCount.set(result.count);
          }

          this.attachments.set(result.items);

          const currentPath = this.currentDirectory();
          if (!currentPath || !result.prefixes) {
            this.subdirectories.set([]);
          } else {
            const sdirs = new Set<string>();
            for (const path of result.prefixes) {
              if (path !== currentPath) {
                sdirs.add(path.substring(0, currentPath.length + path.substring(currentPath.length).indexOf('/') + 1));
              }
            }
            this.subdirectories.set(Array.from(sdirs));
          }
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error("Failed to load files.");
        }
      }).add(() => {
        this.isLoading.set(false);
      });
    });
  }

  ngOnInit() {
    this.attachmentService
      .getPrefixes(this.dataset().id, 0, 255)
      .subscribe({
        next: (result: PrefixListResponse) => {
          const directories = parseDirectories(result.items);
          this.directories.set(directories);

          const root = directories.get('/');
          if (root) {
            this.currentDirectory.set('/');
          }
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to load file directories.');
        },
      }).add(() => {
        this.isInitialized.set(true);
      });
  }

  open(attachment: Attachment) {
    this.dialog.open(AttachmentItemComponent, {
      data: {
        attachment: attachment,
        isDatasetPublic: this.dataset().type === DatasetType.Public,
      },
      minWidth: '360px',
    });
  }

  authorize(attachment: Attachment): boolean {
    if (this.dataset().type !== DatasetType.Public ||
      !attachment.released) {
      return true;
    }

    return Date.parse(attachment.released) > Date.now();
  }

  openSearch() {
    const dialogRef = this.dialog.open(AttachmentSearchComponent, {
      data: {
        search: this.search(),
        prefix: this.currentDirectory(),
        from: this.from(),
        until: this.until(),
      },
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((data: { search: string, prefix: string, from: Date | null, until: Date | null } | null) => {
      if (data == null) {
        return;
      }

      this.search.set(data.search);
      this.q.set(data.search);
      this.currentDirectory.set(data.prefix);
      this.from.set(data.from);
      this.until.set(data.until);
    });
  }
}
